import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { getMessaging, onMessage } from "firebase/messaging";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBRMszglAN7P8QSZHLZTW4E7sAQqq7rSeY",
  authDomain: "bg-dimitarsi89-test.firebaseapp.com",
  databaseURL:
    "https://bg-dimitarsi89-test-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "bg-dimitarsi89-test",
  storageBucket: "bg-dimitarsi89-test.appspot.com",
  messagingSenderId: "731950264119",
  appId: "1:731950264119:web:508c189082963fa25cb4b2",
  measurementId: "G-BQT04PPKEE",
};

// Initialize Firebase
initializeApp(firebaseConfig);

const messaging = getMessaging();
onMessage(messaging, (payload) => {
  console.log("Message received. ", payload);
  // ...
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
