import "./App.css";
import React from "react";
import { getMessaging, getToken } from "firebase/messaging";

const noop = () => {};

class App extends React.Component {
  constructor() {
    super();

    const messaging = getMessaging();
    getToken(messaging, {
      vapid:
        "BIGEk30Yoq23Gt3C4ytzijXeNTuP1bBGByk4i5ImOruA7ViM6UxJX0m5gjdIZdbRJhQW-a7VQUknH7ZvkZTciGI",
    }).then((token) => {
      console.log(token);
    });
  }

  render() {
    return (
      <div className="App">
        <button type="button" onClick={noop}>
          Allow Notifications
        </button>
      </div>
    );
  }
}

export default App;
